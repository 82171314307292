import React, {useContext, useEffect, useState} from 'react'
import moment from 'moment'
import {KTSVG} from '../../../_metronic/helpers'
import ToolbarCustom from '../../../_metronic/layout/components/toolbar/toolbars/ToolbarCustom'

import { CustomersContext, ProductsContext } from '../../context'
import PurchaseDetailModal from './PurchaseDetailModal'
import { GridActionsCellItem } from '@mui/x-data-grid'
import DataGridComponent from '../../components/DataGrid'

const PurchasesPage = () => {
  
  const {
    customers,
    customersLoaded,
    fetchCustomers,
    purchases,
    purchasesLoaded,
    fetchPurchases,
  } = useContext(CustomersContext)

  const {
    resorts,
    resortsLoaded,
    fetchResorts,
    domains,
    domainsLoaded,
    fetchDomains,
    durations,
    durationsLoaded,
    fetchDurations,
    genericProducts,
    genericProductsLoaded,
    specificProducts,
    specificProductsLoaded,
    localProducts,
    localProductsLoaded,
    legacyProducts,
    legacyProductsLoaded,
    fetchProducts,
  } = useContext(ProductsContext)

  const [currentPurchase, setCurrentPurchase] = useState(null)
  const [filteredPurchases, setFilteredPurchases] = useState([])
  const [validPurchases, setValidPurchases] = useState([])
  const [showExportModal, setShowExportModal] = useState(false)
  const [exportRangeStart, setExportRangeStart] = useState(null)
  const [exportRangeEnd, setExportRangeEnd] = useState(null)
  const [search, setSearch] = useState('')

  useEffect(() => {
    if(!customersLoaded) {
      fetchCustomers()
    }
  }, [customersLoaded, fetchCustomers])

  useEffect(() => {
    if(!purchasesLoaded) {
      fetchPurchases()
    }
  }, [purchasesLoaded, fetchPurchases])

  useEffect(() => {
    if(!resortsLoaded) {
      fetchResorts()
    }
  }, [resortsLoaded, fetchResorts])

  useEffect(() => {
    if(!domainsLoaded) {
      fetchDomains()
    }
  }, [domainsLoaded, fetchDomains])

  useEffect(() => {
    if(!durationsLoaded) {
      fetchDurations()
    }
  }, [durationsLoaded, fetchDurations])

  useEffect(() => {
    if(!genericProductsLoaded) {
      fetchProducts('generic')
    }
  }, [genericProductsLoaded, fetchProducts])

  useEffect(() => {
    if(!specificProductsLoaded) {
      fetchProducts('specific')
    }    
  }, [specificProductsLoaded, fetchProducts])

  useEffect(() => {
    if(!localProductsLoaded) {
      fetchProducts('local')
    }    
  }, [localProductsLoaded, fetchProducts])

  useEffect(() => {
    if(!legacyProductsLoaded) {
      fetchProducts('legacy')
    }    
  }, [legacyProductsLoaded, fetchProducts])

  useEffect(() => {
    // update current purchase if data changes
    if(currentPurchase?.id) {
      const purchase = purchases.find(p => p.id === currentPurchase.id)
      setCurrentPurchase(purchase)
    }
  }, [purchases])

  const onViewClick = (purchase) => {
    setCurrentPurchase(purchase)
  }

  const convertToTableItem = (purchase) => {
    return {
      ...purchase,
      customer_name: `${purchase.buyer.firstname} ${purchase.buyer.lastname}`,
      customer_email: purchase.buyer.email,
      product_name: purchase.product_name,
      paid_at_label: purchase.paid_at ? moment(purchase.paid_at).format('DD/MM/YYYY HH:mm') : purchase.meta?.updated ? moment(purchase.meta.updated).format('DD/MM/YYYY HH:mm') : 'N/A',
      documents_valid: Boolean(purchase.invoice_url) && Boolean(purchase.contract_url),
    }
  }

  useEffect(() => {
    let fp = [...validPurchases]

    if(search) {
      const searchTokens = search.split(' ').filter(t => t.length > 0)
      fp = fp.filter(purchase => {
        const fields = [purchase.buyer.firstname, purchase.buyer.lastname, purchase.buyer.email, purchase.id]
        // check if all tokens are found in at least one field
        for(let token of searchTokens) {
          if(!fields.find(f => f.toLowerCase().includes(token.toLowerCase()))) {
            return false
          }
        }
        return true
      })
    }

    fp.sort((a, b) => {
      const aDate = a.paid_at || a.meta?.updated || a.meta?.created || 0
      const bDate = b.paid_at || b.meta?.updated || b.meta?.created || 0
      if(aDate < bDate) return 1
      if(aDate > bDate) return -1
      return 0
    })
    setFilteredPurchases(fp)
  }, [customers, validPurchases, search])

  useEffect(() => {

    let fp = []
    for(let p of purchases) {
      if(p.status === 'paid' || p.status === 'refunded') {
        fp.push(p)
      }
    }
    setValidPurchases(fp)
  }, [purchases])

  const itemWithId = (array, id) => {
    return array.find(i => i.id === id)
  }

  const formattedPrice = (price) => {
    return `€ ${parseFloat((price / 100)).toFixed(2)}`.replace('.', ',')
  }

  const promptExport = () => {
    if(!legacyProducts || !localProductsLoaded || !specificProductsLoaded || !genericProductsLoaded || !resortsLoaded || !domainsLoaded || !durationsLoaded) {
      alert('Wait for data to be loaded. Please try again in a moment.')
      return
    }
    setShowExportModal(true)
  }

  const handleExport = (range) => {

    // const columns = [
    //   'Purchase ID',
    //   'Product name (WebApp)',
    //   'Generic product ID (WebApp)',
    //   'Specific product ID (WebApp)',
    //   'Local product ID (WebApp)',
    //   'Legacy product ID (WebApp)',
    //   'Product name (WTW)',
    //   'Product ID (WTW / Mutuaide)',
    //   'Purchase date',
    //   'Amount paid',
    //   'PPV unitaire',
    //   'Com distrib unitaire',
    //   'Com distrib à percevoir',
    //   'Nombre de produits achetés',
    //   'Customer email',
    //   'Customer name',
    //   'Customer company',
    //   'Insured persons',
    //   'Resort',
    //   'Domain',
    //   'Duration',
    //   'Dates',
    //   'Proofs of purchase',
    //   'Contract url',
    //   'Refunded',
    //   'Refunded at',
    //   'Refunded amount',
    // ]

    // update 18/12/2024
    const columns = [
      'Purchase ID', // A
      'Generic product name (WebApp)', // B
      'Specific product name (WebApp)', // C
      'Local product ID (WebApp)', // D
      'Product ID (WTW / Mutuaide)', // E
      'PPV unitaire', // F
      'Q-P tech unitaire', // G
      'Com distrib unitaire', // H
      'Operator share (unitaire)', // I
      'Clubs share (unitaire)', // J
      'Others share (unitaire)', // K
      'Publisher share (unitaire)', // L
      'Purchase date', // M
      'Nombre de produits achetés', // N
      'Amount paid', // O
      'Com distrib à percevoir', // P
      'Refunded', // Q
      'Refunded at', // R
      'Refunded amount', // S
      'Voucher', // T
      'Customer email', // U
      'Customer name', // V
      'Customer company', // W
      'Insured persons', // X
      'Resort', // Y
      'Domain', // Z
      'Duration', // AA
      'Dates of insurance', // AB
      'Contract url' // AC
    ]

    const csvHeader = columns.join(';') + '\n'

    if(!legacyProducts || !localProductsLoaded || !specificProductsLoaded || !genericProductsLoaded || !resortsLoaded || !domainsLoaded || !durationsLoaded) {
      alert('Wait for data to be loaded. Please try again in a moment.')
      return
    }

    const EXPORT_DATE_FORMAT = 'YYYY-MM-DD HH:mm'
    const EXPORT_DATE_FORMAT_SHORT = 'YYYY-MM-DD'

    let csv = csvHeader
    let exportCount = 0
    let totalPaid = 0
    let totalDistributorAmount = 0
    const sortedPurchases = [...validPurchases]
    sortedPurchases.sort((a, b) => {
      const aDate = a.paid_at || a.meta?.updated || a.meta?.created || 0
      const bDate = b.paid_at || b.meta?.updated || b.meta?.created || 0
      if(aDate < bDate) return -1
      if(aDate > bDate) return 1
      return 0
    })
    for(let purchase of sortedPurchases) {
      if(range && exportRangeStart && exportRangeEnd) {
        const purchaseMoment = moment(purchase.paid_at || purchase.meta?.updated || purchase.meta?.created)
        const purchaseYear = purchaseMoment.year()
        const purchaseMonth = purchaseMoment.month() + 1
        const purchaseDay = purchaseMoment.date()

        const [exportRangeStartYear, exportRangeStartMonth, exportRangeStartDay] = exportRangeStart.split('-').map(e => parseInt(e))
        const [exportRangeEndYear, exportRangeEndMonth, exportRangeEndDay] = exportRangeEnd.split('-').map(e => parseInt(e))

        if(purchaseYear < exportRangeStartYear || purchaseYear > exportRangeEndYear) {
          continue
        }
        if(purchaseYear === exportRangeStartYear && purchaseMonth < exportRangeStartMonth) {
          continue
        }
        if(purchaseYear === exportRangeEndYear && purchaseMonth > exportRangeEndMonth) {
          continue
        }
        if(purchaseYear === exportRangeStartYear && purchaseMonth === exportRangeStartMonth && purchaseDay < exportRangeStartDay) {
          continue
        }
        if(purchaseYear === exportRangeEndYear && purchaseMonth === exportRangeEndMonth && purchaseDay > exportRangeEndDay) {
          continue
        }
      }

      const localProduct = itemWithId(localProducts || [], purchase.product_local)
      const specificProduct = itemWithId(specificProducts || [], purchase.product_specific)
      const genericProduct = itemWithId(genericProducts || [], purchase.product_generic)
      const legacyProduct = itemWithId(legacyProducts || [], purchase.product)

      const productData = {...(genericProduct || {}), ...(specificProduct || {}), ...(localProduct || {}), ...(legacyProduct || {})}

      console.log({
        purchase,
        localProduct,
        specificProduct,
        genericProduct,
        legacyProduct,
      })

      const resort = itemWithId(resorts || [], purchase.resort)
      const domain = itemWithId(domains || [], purchase.domain)
      const duration = itemWithId(durations || [], purchase.duration)
      const purchaseId = purchase.id
      // const productName = productData?.name?.fr || purchase.product_name || 'N/A'
      const genericProductName = (genericProduct || legacyProduct)?.name?.fr || purchase.product_name || 'N/A'
      const specificProductName = specificProduct?.name?.fr || purchase.product_name || 'N/A'
      // const legacyProductId = legacyProduct?.id || purchase.product || 'N/A'
      const localProductId = localProduct?.id || purchase.product_local || 'N/A'
      // const specificProductId = specificProduct?.id || purchase.product_specific || 'N/A'
      // const genericProductId = genericProduct?.id || purchase.product_generic || 'N/A'
      // const productNameWtw = productData?.name?.fr || purchase.product_name || 'N/A'
      const productIdWtw = productData?.internal_id || 'N/A'
      const purchaseDate = purchase.paid_at ? moment(purchase.paid_at).format(EXPORT_DATE_FORMAT) : purchase.meta?.updated ? moment(purchase.meta.updated).format('DD/MM/YYYY HH:mm') : 'N/A'
      const amountPaid = formattedPrice(purchase.amount)
      const datesArray = purchase.dates_formatted || purchase.dates?.map(d => moment(d).format('DD/MM/YYYY')) || []
      // const productOption = product.options[`${purchase.resort}/${purchase.domain}/${purchase.duration}`]
      // const distributorUnitPrice = 'N/A' // productUnitPrice * 0.8
      // const distributorAmount = 'N/A' // distributorUnitPrice * purchase.quantity
      const numberOfProducts = purchase.people.length *  (duration.dates_required ? datesArray.length : 1)
      const productUnitPrice = formattedPrice(purchase.amount / numberOfProducts) // productOption?.price ? formattedPrice(productOption.price * 100) : formattedPrice()
      const totalTechnicalShare = (specificProduct?.share_insurer || 0) + (specificProduct?.share_broker || 0) + (specificProduct?.share_co_broker || 0)
      const priceHt = localProduct?.price_ht || 0
      const customerEmail = purchase.buyer.email
      const customerName = `${purchase.buyer.firstname} ${purchase.buyer.lastname}`
      const customerCompany = purchase.buyer.comany_name || purchase.buyer.companyName || ''
      const insuredPersons = purchase.people.map(p => `${p.firstname} ${p.lastname}`).join(', ')
      const resortName = resort?.name?.fr || 'N/A'
      const domainName = domain.title.fr
      const durationName = duration.title.fr
      const dates = datesArray.join(', ')
      // const proofsOfPurchase = (purchase.proofs_of_purchase || []).join(', ')
      const contractUrl = purchase.contract_url || 'N/A'
      const refunded = purchase.status === "refunded" ? "YES" : "NO"
      const refundedAt = purchase.status === "refunded" ? purchase.meta.updated ? moment(purchase.meta.updated).format(EXPORT_DATE_FORMAT) : 'N/A' : ''
      const refundedAmount = purchase.status === "refunded" ? purchase.amount_refunded ? formattedPrice(purchase.amount_refunded) : 'N/A' : ''
      const operatorShare = localProduct?.share_operator || 0
      const clubShare = localProduct?.share_club || 0
      const otherShare = localProduct?.share_other || 0
      const publisherShare = localProduct?.share_webapp || 0
      const distributorAmount = publisherShare * numberOfProducts
      const voucherCode = purchase.voucher_code || ''

      const line = [
        purchaseId, // A
        genericProductName, // B
        specificProductName, // C
        localProductId, // D
        productIdWtw, // E
        productUnitPrice, // F
        totalTechnicalShare, // G
        priceHt - totalTechnicalShare, // H
        operatorShare, // I
        clubShare, // J
        otherShare, // K
        publisherShare, // L
        purchaseDate, // M
        numberOfProducts, // N
        amountPaid, // O
        distributorAmount, // P
        refunded, // Q
        refundedAt, // R
        refundedAmount, // S
        voucherCode, // T
        customerEmail, // U
        customerName, // V
        customerCompany, // W
        insuredPersons, // X
        resortName, // Y
        domainName, // Z
        durationName, // AA
        dates, // AB
        contractUrl, // AC
      ]
      csv += line.join(';') + '\n'
      exportCount++
      totalPaid += purchase.amount
      totalDistributorAmount += distributorAmount
    }
    if(exportCount > 0) {
      const totalPaidColumnIndex = columns.indexOf('Amount paid')
      const comDistribAPercevoirColumnIndex = columns.indexOf('Com distrib à percevoir')
      const line = Array(columns.length).fill('')
      line[totalPaidColumnIndex] = formattedPrice(totalPaid)
      line[comDistribAPercevoirColumnIndex] = formattedPrice(totalDistributorAmount * 100)
      csv += line.join(';') + '\n'
    }
    const a = document.createElement('a')
    const fileName = 'Assuranceski-Purchases-Export'
    // print range in suffix or current date if no range in format DD-MM-YYYY
    const fileSuffix = range ? `${exportRangeStart}_${exportRangeEnd}` : `all-(${moment().format(EXPORT_DATE_FORMAT_SHORT)})`
    a.download = `${fileName}-${fileSuffix}.csv`
    a.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv)
    a.click()
    setShowExportModal(false)
  }

  return (
    <>
      <ToolbarCustom
        pageTitle={'Purchases'}
        filterElements={[
        <div
          key={`filter-element-search`}
          data-kt-search-element='form'
          className='w-300px position-relative'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
          />
          <input
            type='text'
            className='form-control form-control-flush ps-9 pe-9'
            name='search'
            value={search}
            placeholder='Customer name, email, purchase ID'
            data-kt-search-element='input'
            onChange={(e) => setSearch(e.target.value)}
          />
          {/* clear search field */}
          {search && (
            <div
              className='btn btn-flush btn-active-color-primary position-absolute top-50 ms-0 translate-middle-y end-0'
              onClick={() => setSearch('')}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500'
              />
            </div>
          )}
        </div>,
        <div
          key={`filter-element-export`}
          className='w-100 position-relative'
        >
          <div className='btn btn-primary btn-sm' onClick={promptExport}>Export</div>
        </div>]}
        loading={!purchasesLoaded}
        />
      {/* begin::Row */}

      <div className='mt-1'>
        <DataGridComponent
          id="purchases"
          rows={filteredPurchases.map(convertToTableItem)}
          columns={[{
            field: 'product_name',
            headerName: 'Product',
            flex: 1,
          }, {
            field: 'id',
            headerName: 'Purchase ID',
          }, {
            field: 'resort',
            headerName: 'Resort',
            valueFormatter: (value) => itemWithId(resorts, value)?.name?.fr || 'N/A',
          }, {
            field: 'domain',
            headerName: 'Domain',
            valueFormatter: (value) => itemWithId(domains, value)?.title?.fr || 'N/A',
          }, {
            field: 'duration',
            headerName: 'Duration',
            valueFormatter: (value) => itemWithId(durations, value)?.title?.fr || 'N/A',
          }, {
            field: 'amount',
            headerName: 'Amount',
            valueFormatter: (value) => `${(value / 100).toFixed(2)}€`,
          }, {
            field: 'customer_name',
            headerName: 'Customer',
            flex: 1,
          }, {
            field: 'customer_email',
            headerName: 'Email',
            flex: 1,
          }, {
            field: 'paid_at_label',
            headerName: 'Purchase date',
          }, {
            field: 'status',
            headerName: 'Refunded',
            valueFormatter: (value) => value === 'refunded' ? 'YES' : 'NO',
            width: 80,
          }, {
            field: 'voucher_code',
            headerName: 'Voucher code',
            width: 120,
          }, {
            field: 'documents_valid',
            headerName: 'Docs',
            width: 80,
            renderCell: ({ row }) => {
              return <span><i className={`bi fs-4 ${row.documents_valid ? 'bi-check2-circle text-success' : 'bi-x-circle text-danger'}`}></i></span>
            },
          }, {
            field: 'actions',
            type: 'actions',
            headerName: '',
            cellClassName: 'actions',
            getActions: ({ id }) => {
              return [
                <GridActionsCellItem
                  key={`view-${id}`}
                  icon={<i className="bi bi-eye text-primary fs-4"></i>}
                  label="View"
                  className="textPrimary"
                  onClick={() => onViewClick(itemWithId(filteredPurchases, id))}
                  color="inherit"
                />,
              ];
            },
            width: 60
          }]}
          pageSizeOptions={[20, 50, 100]}
        />
      </div>
      
      <PurchaseDetailModal
        show={Boolean(currentPurchase)}
        handleClose={() => setCurrentPurchase(null)}
        purchase={currentPurchase} />
      {/** begin:export prompt modal */}
      {/** prompt to select dates from - to */ }
      <>
        <div
          className={`modal fade ${showExportModal ? 'show' : 'pe-none'} d-block`}
          id='exportModal'
          tabIndex='-1'
          aria-hidden={true}
        >
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md'>
            <div className={`modal-content ${!showExportModal ? 'pe-none' : ''}`}>
              <div className='modal-header'>
                <h2 className='fw-bolder'>Export Purchases</h2>
                <div className='btn btn-icon btn-sm btn-active-icon-primary' data-bs-dismiss='modal' onClick={() => setShowExportModal(false)}>
                  <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
                </div>
              </div>
              <div className='modal-body'>
                <div className='d-flex flex-column'>
                  <div className='fw-bold fs-6 mb-3'>Select date range</div>
                  <div className='d-flex flex-column'>
                    <div className='d-flex flex-row mb-3'>
                      <div className='d-flex flex-column flex-grow-1 pe-3'>
                        <label className='fw-bold fs-6 mb-1'>From</label>
                        <input type='date' className='form-control form-control-solid' onChange={(e) => setExportRangeStart(e.target.value)} />
                      </div>
                      <div className='d-flex flex-column flex-grow-1 ps-3'>
                        <label className='fw-bold fs-6 mb-1'>To</label>
                        <input type='date' className='form-control form-control-solid' onChange={(e) => setExportRangeEnd(e.target.value)} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal-footer flex-center'>
                <button
                  type='button'
                  className='btn btn-light btn-active-light-primary fw-bold me-2'
                  data-bs-dismiss='modal'
                  onClick={() => setShowExportModal(false)}
                >
                  Cancel
                </button>
                <button
                  type='button'
                  className='btn btn-primary fw-bold'
                  onClick={() => handleExport(true)}
                  disabled={!exportRangeStart || !exportRangeEnd}
                >
                  Export range
                </button>
                <button
                  type='button'
                  className='btn btn-primary fw-bold'
                  onClick={() => handleExport(false)}
                  >
                    Export all
                  </button>
              </div>
            </div>
          </div>
        </div>
        <div className={`modal-backdrop fade ${showExportModal ? 'show' : 'pe-none'}`}></div>
      </>
    </>
  )
}

const PurchasesWrapper = () => {
  return (
    <>
      <PurchasesPage />
    </>
  )
}

export default PurchasesWrapper

import React, {useContext, useState} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { CustomersContext, ProductsContext } from '../../context'
import moment from 'moment'
import { NotificationsContext } from '../../context/notifications/notificationsState'
import { itemWithId } from '../../utils'
// import {defaultCreateAppData, ICreateAppData} from '../'
// import {StepperComponent} from '../../../assets/ts/components'
// import {KTSVG} from '../../../helpers'
// import {Step1} from './steps/Step1'
// import {Step2} from './steps/Step2'
// import {Step3} from './steps/Step3'
// import {Step4} from './steps/Step4'
// import {Step5} from './steps/Step5'

const modalsRoot = document.getElementById('root-modals') || document.body

const PurchaseOrderDetailModal = ({show, handleClose, purchase}) => {

  const { markPurchaseOrderPaid, markPurchaseOrderCancelled, remindPurchaseOrder } = useContext(CustomersContext)
  const { durations } = useContext(ProductsContext)
  const { toast } = useContext(NotificationsContext)
  const [loading, setLoading] = useState(null)

  const duration = itemWithId(durations, purchase?.duration)

  const handleViewDocument = (documentType) => () => {
    let url = ''
    switch(documentType) {
      case 'summary':
        url = purchase.contract_url
        break
      case 'cg':
        url = purchase.cg
        break
      case 'ipid':
        url = purchase.ipid
        break
      case 'apple-wallet-pass':
        url = purchase.apple_wallet_pass_url
        break
      case 'google-wallet-pass':
        url = purchase.google_wallet_pass_url
        break
      case 'purchase_order':
        url = purchase.purchase_order_url
        break
    }
    if(!url) return alert(`${documentType} not available`)
    return window.open(url, '_blank').focus()
  }

  const handlePromptPaid = async () => {
    if(!window.confirm('Are you sure you want to mark this purchase order as paid?')) return
    setLoading('paid')
    await markPurchaseOrderPaid(purchase.id)
    toast({ message: `Purchase order successfully updated`, type: 'success' })
    setLoading(null)
  }

  const handlePromptCancel = async () => {
    if(!window.confirm('Are you sure you want to cancel this purchase order?')) return
    setLoading('cancel')
    await markPurchaseOrderCancelled(purchase.id)
    toast({ message: `Purchase order successfully updated`, type: 'success' })
    setLoading(null)
  }

  const handlePromptRemind = async () => {
    if(!window.confirm('Are you sure you want to send a reminder to the customer?')) return
    setLoading('reminder')
    await remindPurchaseOrder(purchase.id)
    toast({ message: `Reminder sent`, type: 'success' })
    setLoading(null)
  }


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      // onEntered={loadStepper}
      backdrop={true}
    >

      <div className='modal-body py-lg-10 px-lg-10'>

        {/*begin::Content */}
        <div className='flex-row-fluid py-lg-5 px-lg-15'>
          <form noValidate id='kt_modal_create_app_form'>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Reference ID</span>
              </label>
              <input
                disabled
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='country-name'
                placeholder=''
                value={purchase?.id}
              />
            </div>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Amount</span>
              </label>
              <input
                disabled
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='country-name'
                placeholder=''
                value={`${(purchase?.amount / 100).toFixed(2)}€`}
              />
            </div>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Created date </span>
              </label>
              <input
                disabled
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='country-name'
                placeholder=''
                value={moment(purchase?.meta?.created).format("DD/MM/YYYY HH:mm")}
              />
            </div>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Customer email</span>
              </label>
              <input
                disabled
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='country-name'
                placeholder=''
                value={purchase?.billing?.email}
              />
            </div>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Customer name</span>
              </label>
              <input
                disabled
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='country-name'
                placeholder=''
                value={`${purchase?.billing?.first_name} ${purchase?.billing?.last_name}`}
              />
            </div>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Status</span>
              </label>
              <input
                disabled
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='country-name'
                placeholder=''
                value={purchase?.status}
              />
            </div>
            {/* <!-- display dates of insurance --> */}
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Insured dates</span>
              </label>
              <div className='d-flex flex-column'>
                <input
                  disabled
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  name='country-name'
                  placeholder=''
                  value={duration?.dates_required ? (purchase?.dates_formatted || []).join(", ") : duration?.title?.fr}
                />
                {/* {duration?.dates_required && <span>{(purchase?.dates_formatted || []).join(", ")}</span>}
                {!duration?.dates_required && <span>{duration?.title?.fr}</span>} */}
              </div>
            </div>
            {purchase?.reminders && purchase?.reminders.length > 0 && <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Reminders</span>
              </label>
              <ul>
                {purchase?.reminders.map((reminder, index) => (
                  <li key={index}>
                    <span>{moment(reminder.sent_at).format('DD/MM/YYYY HH:mm')} ({reminder.type})</span>
                  </li>
                ))}
              </ul>
            </div>}

            <div className='d-flex flex-row-fluid justify-content-start align-items-center gap-2 gap-lg-3 mb-5'>
              { purchase?.status === 'pending' && <button
                type='button'
                className='btn btn-danger '
                onClick={handlePromptCancel}
                disabled={loading === 'cancel'}
              >
                { 'Cancel and archive' }
                { loading === 'cancel' && <span className='spinner-border spinner-border-sm ms-2'></span> }
              </button> }
              <button
                type='button'
                className='btn btn-primary ml-auto'
                onClick={handleViewDocument('purchase_order')}
              >
                { 'View purchase order' }
              </button>
              { purchase?.status === 'pending' && <button
                type='button'
                className='btn btn-primary'
                disabled={loading === 'reminder'}
                onClick={handlePromptRemind}
              >
                { 'Send reminder' }
                { loading === 'reminder' && <span className='spinner-border spinner-border-sm ms-2'></span> }
              </button> }
              { purchase?.status === 'pending' && <button
                type='button'
                className='btn btn-success'
                onClick={handlePromptPaid}
                disabled={loading === 'paid'}
              >
                { 'Mark as paid' }
                { loading === 'paid' && <span className='spinner-border spinner-border-sm ms-2'></span> }
              </button> }
              
            </div>
            {/*begin::Actions */}
            <div className='d-flex flex-row-fluid justify-content-between align-items-center gap-2 gap-lg-3'>
              <div className='me-2'>
                <button
                  type='button'
                  className='btn btn-lg'
                  data-kt-stepper-action='next'
                  onClick={handleClose}
                >
                  { 'Close' }
                </button>
              </div>
            </div>
            {/*end::Actions */}
          </form>
          {/*end::Form */}
        </div>
        {/*end::Content */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export default PurchaseOrderDetailModal

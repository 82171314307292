import React, { useContext, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  CardsWidget7,
} from '../../../_metronic/partials/widgets'
import { ArticlesContext, ContactContext, CustomersContext, ProductsContext } from '../../context'

const DashboardPage = () => {
  const {
    articles,
    articlesLoaded,
    fetchArticles,
  } = useContext(ArticlesContext)

  const {
    purchaseOrders,
    purchaseOrdersLoaded,
    fetchPurchaseOrders,
  } = useContext(CustomersContext)
    
  const {
    genericProducts,
    genericProductsLoaded,
    specificProducts,
    specificProductsLoaded,
    localProducts,
    localProductsLoaded,
    fetchProducts,
    resellersLoaded,
    fetchResellers,
    resellers,
    countriesLoaded,
    fetchCountries,
    countries,
    regionsLoaded,
    fetchRegions,
    regions,
    resortsLoaded,
    fetchResorts,
    resorts
  } = useContext(ProductsContext)

  const { contactForms, contactFormsLoaded, fetchContactForms } = useContext(ContactContext)

  useEffect(() => {
    if(!contactFormsLoaded) {
      fetchContactForms()
    }
  }, [contactFormsLoaded, fetchContactForms])

  useEffect(() => {
    if(!articlesLoaded) {
      fetchArticles()
    }
  }, [articlesLoaded, fetchArticles])

  useEffect(() => {
    if(!genericProductsLoaded) {
      fetchProducts('generic')
    }
  }, [genericProductsLoaded, fetchProducts])

  useEffect(() => {
    if(!specificProductsLoaded) {
      fetchProducts('specific')
    }
  }, [specificProductsLoaded, fetchProducts])

  useEffect(() => {
    if(!localProductsLoaded) {
      fetchProducts('local')
    }
  }, [localProductsLoaded, fetchProducts])

  useEffect(() => {
    if(!resellersLoaded) {
      fetchResellers()
    }
  }, [resellersLoaded, fetchResellers])

  useEffect(() => {
    if(!countriesLoaded) {
      fetchCountries()
    }
  }, [countriesLoaded, fetchCountries])

  useEffect(() => {
    if(!resortsLoaded) {
      fetchResorts()
    }
  }, [resortsLoaded, fetchResorts])

  useEffect(() => {
    if(!regionsLoaded) {
      fetchRegions()
    }
  }, [regionsLoaded, fetchRegions])

  useEffect(() => {
    if(!purchaseOrdersLoaded) {
      fetchPurchaseOrders()
    }
  }, [purchaseOrdersLoaded, fetchPurchaseOrders])

  const pendingContactForms = contactForms.filter(contactForm => !contactForm.resolved)

  const pendingPurchaseOrders = purchaseOrders.filter(purchaseOrder => purchaseOrder.status === 'pending')

  return (
    <>
      {/* begin::Row */}

      <div className='row g-5 g-xl-10 mb-2'>
        <CardsWidget7
          link='/requests'
          title={!contactFormsLoaded ? 'Pending contact requests' : pendingContactForms.length > 0 ? `Pending contact request${pendingContactForms.length > 1 ? 's' : ''}` : 'No pending contact requests' }
          className={`col-md-3 mb-5 mb-xl-10 ${!contactFormsLoaded ? 'bg-warning' : pendingContactForms.length > 0? 'bg-danger' : 'bg-success'}`}
          textClassName='text-white'
          statsClassName='text-white fs-2x fw-bolder'
          description='Contact requests'
          icon={false}
          stats={pendingContactForms.length || ''}
          labelColor='dark'
          textColor='gray-300'
          loading={!contactFormsLoaded}
        />
        <CardsWidget7
          link='/purchase-orders'
          title={!purchaseOrdersLoaded ? 'Pending transfer orders' : pendingPurchaseOrders.length > 0 ? `Pending transfer order${pendingPurchaseOrders.length > 1 ? 's' : ''}` : 'No pending transfer orders' }
          className={`col-md-3 mb-5 mb-xl-10 ${!purchaseOrdersLoaded ? 'bg-warning' : pendingPurchaseOrders.length > 0? 'bg-warning' : 'bg-success'}`}
          textClassName='text-white'
          statsClassName='text-white fs-2x fw-bolder'
          description='Transfer orders'
          icon={false}
          stats={pendingPurchaseOrders.length || ''}
          labelColor='dark'
          textColor='gray-300'
          loading={!purchaseOrdersLoaded}
        />
      </div>
      
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <CardsWidget7
          link='/articles'
          title={'Articles'}
          className='col-md-3 h-md-50 mb-5 mb-xl-10'
          description='Articles'
          icon={false}
          stats={articles.length}
          loading={!articlesLoaded}
          labelColor='dark'
          textColor='gray-300'
        />
          
        <CardsWidget7
          link='/generic-products'
          title={'Generic Products'}
          className='col-md-3 mb-5 mb-xl-10'
          description='Generic Products'
          icon={false}
          stats={genericProducts.length}
          labelColor='dark'
          textColor='gray-300'
          loading={!genericProductsLoaded}
        />

        <CardsWidget7
          link='/specific-products'
          title={'Specific Products'}
          className='col-md-3 mb-5 mb-xl-10'
          description='Specific Products'
          icon={false}
          stats={specificProducts.length}
          labelColor='dark'
          textColor='gray-300'
          loading={!specificProductsLoaded}
        />

        <CardsWidget7
          link='/local-products'
          title={'Local Products'}
          className='col-md-3 mb-5 mb-xl-10'
          description='Local Products'
          icon={false}
          stats={localProducts.length}
          labelColor='dark'
          textColor='gray-300'
          loading={!localProductsLoaded}
        />       
        
        <CardsWidget7
          link='/network'
          title={'Network'}
          className='col-md-3 mb-5 mb-xl-10'
          description='Network'
          icon={false}
          stats={resellers.length}
          labelColor='dark'
          textColor='gray-300'
          loading={!resellersLoaded}
        />
        
        <CardsWidget7
          link='/countries'
          title={'Countries'}
          className='col-md-3 mb-5 mb-xl-10'
          description='Countries'
          icon={false}
          stats={countries.length}
          labelColor='dark'
          textColor='gray-300'
          loading={!countriesLoaded}
        />
        
        <CardsWidget7
          link='/regions'
          title={'Regions'}
          className='col-md-3 mb-5 mb-xl-10'
          description='Regions'
          icon={false}
          stats={regions.length}
          labelColor='dark'
          textColor='gray-300'
          loading={!regionsLoaded}
        />
        
        <CardsWidget7
          link='/resorts'
          title={'Resorts'}
          className='col-md-3 mb-5 mb-xl-10'
          description='Resorts'
          icon={false}
          stats={resorts.length}
          labelColor='dark'
          textColor='gray-300'
          loading={!resortsLoaded}
        />
      </div>
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export default DashboardWrapper

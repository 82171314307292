export const SET_ARTICLE_SECTIONS = 'SET_ARTICLE_SECTIONS'
export const SET_ARTICLES = 'SET_ARTICLES'
export const SET_LEGACY_PRODUCTS = 'SET_LEGACY_PRODUCTS'
export const SET_GENERIC_PRODUCTS = 'SET_GENERIC_PRODUCTS'
export const SET_SPECIFIC_PRODUCTS = 'SET_SPECIFIC_PRODUCTS'
export const SET_LOCAL_PRODUCTS = 'SET_LOCAL_PRODUCTS'
export const SET_PRODUCT_SECTIONS = 'SET_PRODUCT_SECTIONS'
export const SET_RESORTS = 'SET_RESORTS'
export const SET_DOMAINS = 'SET_DOMAINS'
export const SET_DURATIONS = 'SET_DURATIONS'
export const SET_RESELLERS = 'SET_RESELLERS'
export const SET_COUNTRIES = 'SET_COUNTRIES'
export const SET_REGIONS = 'SET_REGIONS'
export const SET_CUSTOMERS = 'SET_CUSTOMERS'
export const SET_PURCHASES = 'SET_PURCHASES'
export const SET_NEWS = 'SET_NEWS'
export const SET_INFO_BLOCKS = 'SET_INFO_BLOCKS'
export const SET_CONTACT_FORMS = 'SET_CONTACT_FORMS'
export const SET_FAQS = 'SET_FAQS'
export const SET_PURCHASE_ORDERS = 'SET_PURCHASE_ORDERS'
export const SET_RESORT_PARTNERS = 'SET_RESORT_PARTNERS'
export const SET_PAYMENT_REQUESTS = 'SET_PAYMENT_REQUESTS'
export const ADD_PAYMENT_REQUEST = 'ADD_PAYMENT_REQUEST'
export const SET_GUARANTEES = 'SET_GUARANTEES'
export const SET_EXCLUSIONS = 'SET_EXCLUSIONS'
export const SET_TOAST = 'SET_TOAST'
export const SET_VOUCHERS = 'SET_VOUCHERS'
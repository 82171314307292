import {useContext, useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import ToolbarCustom from '../../../_metronic/layout/components/toolbar/toolbars/ToolbarCustom'

import { ProductsContext } from '../../context'
import VoucherDetailModal from './VoucherDetailModal'
import { GridActionsCellItem } from '@mui/x-data-grid'
import { itemWithId } from '../../utils'
import ImportCollectionModal from '../../components/ImportCollectionModal'
import config from '../../../config.json'
import DataGridComponent from '../../components/DataGrid'

const VouchersPage = () => {
  
  const {
    vouchers,
    vouchersLoaded,
    fetchVouchers,
    createVoucher,
    updateVoucher,
    deleteVoucher,
    genericProductsLoaded,
    specificProductsLoaded,
    localProductsLoaded,
    fetchProducts,
    resortsLoaded,
    fetchResorts,
  } = useContext(ProductsContext)

  const [currentVoucher, setCurrentVoucher] = useState(null)
  const [filteredVouchers, setFilteredVouchers] = useState([])
  const [importCollectionModalOpen, setImportCollectionModalOpen] = useState(false)

  const convertToTableItem = (voucher) => {
    return {
      ...voucher,
      title_label: voucher.title || 'No title',
      code_label: voucher.code || 'No code',
      discount_label: voucher.discount || 0,
      type_label: voucher.single_use ? 'single' : 'interval',
      availability_start_label: voucher.availability_start || '',
      availability_end_label: voucher.availability_end || '',
      available_label: voucher.single_use ? !voucher.use_count : (voucher.availability_start < Date.now() && voucher.availability_end > Date.now()),
    }
  }

  useEffect(() => {
    setFilteredVouchers(vouchers)
  }, [vouchers])

  useEffect(() => {
    if(!vouchersLoaded) {
      fetchVouchers()
    }
  }, [vouchersLoaded, fetchVouchers])

  useEffect(() => {
    if(!localProductsLoaded) {
      fetchProducts('local')
    }
  }, [localProductsLoaded, fetchProducts])

  useEffect(() => {
    if(!genericProductsLoaded) {
      fetchProducts('generic')
    }
  }, [genericProductsLoaded, fetchProducts])

  useEffect(() => {
    if(!specificProductsLoaded) {
      fetchProducts('specific')
    }
  }, [specificProductsLoaded, fetchProducts])
  
  useEffect(() => {
    if(!resortsLoaded) {
      fetchResorts()
    }
  }, [resortsLoaded, fetchResorts])

  const onCreateClick = () => {
    setCurrentVoucher({
      id: '',
      title: '',
      text: '',
    })
  }

  const onEditClick = (voucher) => {
    setCurrentVoucher(voucher)
  }

  const onDetailSubmit = async () => {
    let response
    const data = {...currentVoucher}
    if(!data.id) {
      response = await createVoucher(data)
    } else {
      response = await updateVoucher(data.id, data)
    }
    setCurrentVoucher(null)
  }

  const onDetailDelete = async () => {
    if(!confirm(`Are you sure you want to delete this voucher? (${currentVoucher?.title || currentVoucher.id})`)) return
    let response = await deleteVoucher(currentVoucher.id)
    setCurrentVoucher(null)
  }

  const promptImportCollection = () => {
    setImportCollectionModalOpen(true)
  }

  return (
    <>
      <ToolbarCustom
        pageTitle={'Vouchers'}
        primaryButton={vouchersLoaded && { onClick: onCreateClick, title: 'Add' }}
        loading={!vouchersLoaded}
        filterElements={[(
          config.environment !== 'staging' && <button className='btn btn-sm fw-bold btn-primary' onClick={promptImportCollection}>
            <KTSVG path='/media/icons/duotune/arrows/arr037.svg' className='svg-icon-3' />
            Import from staging
          </button>
        )]}
         />
      {/* begin::Row */}
      <div className='mt-3'>
        {vouchersLoaded && 
          <DataGridComponent
            id="vouchers"
            rows={filteredVouchers.map(convertToTableItem)}
            columns={[{
              field: 'title_label',
              headerName: 'Title',
              flex: 1,
            }, {
              field: 'code_label',
              headerName: 'Code',
              flex: 1,
            }, {
              field: 'discount_label',
              headerName: 'Discount',
              width: 80,
              valueFormatter: (value) => `${value}%`
            }, {
              field: 'type_label',
              headerName: 'Type',
              width: 100,
              valueFormatter: (value) => {
                if(value === 'single') return 'Single use'
                if(value === 'interval') return 'Interval'
                return 'Unknown'
              },
              renderCell: ({ row }) => {
                if(row.type_label === 'single') return <span className='badge badge-success fs-8'>Single use</span>
                if(row.type_label === 'interval') return  <span className='badge badge-primary fs-8'>Interval</span>
                return <span className='badge badge-light-danger fs-8'>Unknown</span>
              }
            }, {
              field: 'available_label',
              headerName: 'Available',
              width: 100,
              renderCell: ({ row }) => {
                if(row.available_label) return <span className='badge badge-success fs-8'>Available</span>
                return <span className='badge badge-danger fs-8'>Unavailable</span>
              }
            }, {
              field: 'availability_start_label',
              headerName: 'Start',
              width: 100,
              valueFormatter: (value) => value ? new Date(value).toLocaleDateString() : ''
            }, {
              field: 'availability_end_label',
              headerName: 'End',
              width: 150,
              valueFormatter: (value) => value ? new Date(value).toLocaleDateString() : ''
            }, {
              field: 'products',
              headerName: 'Products',
              width: 100,
              renderCell: ({ row }) => {
                if(row.all_products) return 'All products'
                if(row.products && row.products.length) {
                  return row.products.length
                }
                return 'No products'
              },
            },{
              field: 'actions',
              type: 'actions',
              headerName: '',
              width: 100,
              cellClassName: 'actions',
              getActions: ({ id }) => {
                return [
                  <GridActionsCellItem
                    icon={<i className="bi bi-pencil-square text-primary"></i>}
                    label="Edit"
                    className="textPrimary"
                    onClick={() => onEditClick(itemWithId(filteredVouchers, id))}
                    color="inherit"
                  />,
                ];
              },
            }]}
            pageSizeOptions={[20, 50, 100]}
          />
        }

        {/* begin::Col */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
          <ListsWidget26 className='h-lg-50' />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6'>
          {/* <EngageWidget10 className='h-md-100' /> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gx-5 gx-xl-10'>
        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 gx-xl-8'>
        <div className='col-xxl-4'>
          <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
        </div>
        <div className='col-xl-8'>
          <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 g-xl-8'>
        <div className='col-xl-4'>
          <ListsWidget2 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget6 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
        </div>
      </div> */}
      {/* end::Row */}

      {/* <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-4'>
          <MixedWidget8
            className='card-xxl-stretch mb-xl-3'
            chartColor='success'
            chartHeight='150px'
          />
        </div>
        <div className='col-xxl-8'>
          <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
        </div>
      </div> */}
      <VoucherDetailModal
        show={Boolean(currentVoucher)}
        handleClose={() => setCurrentVoucher(null)}
        voucher={currentVoucher}
        setVoucher={setCurrentVoucher}
        onSubmit={onDetailSubmit}
        onDelete={onDetailDelete} />
      <ImportCollectionModal
        isOpen={importCollectionModalOpen}
        onClose={() => setImportCollectionModalOpen(false)}
        collectionName={"vouchers"}
        sourceEnvironment={"staging"}
        onImportComplete={async () => {
          // reload collection
          await fetchVouchers()
        }} />
    </>
  )
}

const VouchersWrapper = () => {
  return (
    <>
      <VouchersPage />
    </>
  )
}

export default VouchersWrapper

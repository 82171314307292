import React, { useContext, useReducer } from 'react'
import { PAYMENT_REQUESTS, PURCHASES, USERS } from '../../constants/collections'
import { fetchCollection, fetchDocument, retrieveCustomers, sendPurchaseOrderReminder, updateDocument } from '../../services/firebase'
import { ADD_PAYMENT_REQUEST, SET_CUSTOMERS, SET_PURCHASES, SET_PURCHASE_ORDERS } from '../types'
import customersReducer from './customersReducer'
import { NotificationsContext } from '../notifications/notificationsState'

const CustomersContext = React.createContext()

// eslint-disable-next-line react/prop-types
const CustomersState = ({ children }) => {

  const initialState = {
    customers: [],
    customersLoaded: false,
    purchases: [],
    purchasesLoaded: false,
    purchaseOrders: [],
    purchaseOrdersLoaded: false,
    paymentRequests: [],
  }

  const [state, dispatch] = useReducer(customersReducer, initialState)
  const { toast } = useContext(NotificationsContext)

  const fetchCustomers = async () => {
    const users = await retrieveCustomers()
    const obj = {}
    for(let u of users) {
      obj[u.uid] = u
    }
    const snapshot = await fetchCollection(USERS)
    snapshot.forEach(doc => {
      if(obj[doc.id]) {
        obj[doc.id] = {
          ...obj[doc.id],
          ...doc.data(),
        }
      }
    })
    const array = Object.values(obj)
    setCustomers(array)
  }

  const fetchPurchases = async () => {
    const snapshot = await fetchCollection(PURCHASES)
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setPurchases(array)
  }

  const fetchPaymentRequest = async (id) => {
    const doc = await fetchDocument(`${PAYMENT_REQUESTS}/${id}`)
    const requestData = {
      ...doc.data(),
      id: doc.id
    }
    dispatch({
      type: ADD_PAYMENT_REQUEST,
      payload: requestData
    })
    return requestData
  }

  const setCustomers = (data) => {
    dispatch({
      type: SET_CUSTOMERS,
      payload: data
    })
  }

  const setPurchases = (data) => {
    dispatch({
      type: SET_PURCHASES,
      payload: data
    })
  }

  const setSinglePurchase = (id, data) => {
    let found = false
    const purchases = state.purchases.map(p => {
      if(p.id === id) {
        p = { ...p, ...data }
        found = true
      }
      return p
    })
    if(!found) {
      purchases.push(data)
    }
    setPurchases(purchases)
  }

  const fetchPurchaseOrders = async () => {
    const snapshot = await fetchCollection(PAYMENT_REQUESTS, { field: "payment_method", op: "==", value: "bank-transfer" })
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setPurchaseOrders(array)
  }

  const setPurchaseOrders = (data) => {
    dispatch({
      type: SET_PURCHASE_ORDERS,
      payload: data
    })
  }

  const markPurchaseOrderPaid = async (orderId) => {
    await updateDocument(`${PAYMENT_REQUESTS}/${orderId}`, { status: "paid" })
    const orders = state.purchaseOrders.map(o => {
      if(o.id === orderId) {
        o.status = "paid"
      }
      return o
    })
    setPurchaseOrders(orders)
  }

  const markPurchaseOrderCancelled = async (orderId) => {
    await updateDocument(`${PAYMENT_REQUESTS}/${orderId}`, { status: "cancelled" })
    const orders = state.purchaseOrders.map(o => {
      if(o.id === orderId) {
        o.status = "cancelled"
      }
      return o
    })
    setPurchaseOrders(orders)
  }

  const remindPurchaseOrder = async (orderId) => {
    const response = await sendPurchaseOrderReminder(orderId)
    if(!response.reminders) {
      toast({ message: `Sending reminder failed`, type: 'danger' })
      return false
    }
    const orders = state.purchaseOrders.map(o => {
      if(o.id === orderId) {
        o.reminders = response.reminders
      }
      return o
    })
    setPurchaseOrders(orders)
    toast({ message: `Reminder sent`, type: 'success' })
  }


  return (
    <CustomersContext.Provider value={{
      customers: state.customers,
      customersLoaded: state.customersLoaded,
      purchases: state.purchases,
      purchasesLoaded: state.purchasesLoaded,
      purchaseOrders: state.purchaseOrders,
      purchaseOrdersLoaded: state.purchaseOrdersLoaded,
      paymentRequests: state.paymentRequests,
      fetchCustomers,
      fetchPurchases,
      fetchPurchaseOrders,
      markPurchaseOrderPaid,
      markPurchaseOrderCancelled,
      fetchPaymentRequest,
      setSinglePurchase,
      remindPurchaseOrder
    }}>
      { children }
    </CustomersContext.Provider>
  )
}

export { CustomersContext }

export default CustomersState
import { SET_REGIONS, SET_COUNTRIES, SET_GENERIC_PRODUCTS, SET_SPECIFIC_PRODUCTS, SET_LOCAL_PRODUCTS, SET_RESELLERS, SET_RESORTS, SET_DOMAINS, SET_DURATIONS, SET_PRODUCT_SECTIONS, SET_RESORT_PARTNERS, SET_GUARANTEES, SET_EXCLUSIONS, SET_VOUCHERS, SET_LEGACY_PRODUCTS } from '../types';

const articlesReducer = (state, action) => {
  switch(action.type) {
    case SET_LEGACY_PRODUCTS:
      return {
        ...state,
        legacyProducts: action.payload,
        legacyProductsLoaded: true
      }
    case SET_GENERIC_PRODUCTS:
      return {
        ...state,
        genericProducts: action.payload,
        genericProductsLoaded: true
      }
    case SET_SPECIFIC_PRODUCTS:
      return {
        ...state,
        specificProducts: action.payload,
        specificProductsLoaded: true
      }
    case SET_LOCAL_PRODUCTS:
      return {
        ...state,
        localProducts: action.payload,
        localProductsLoaded: true
      }
    case SET_PRODUCT_SECTIONS:
      return {
        ...state,
        productSections: action.payload,
        productSectionsLoaded: true
      }
    case SET_RESORTS:
      return {
        ...state,
        resorts: action.payload,
        resortsLoaded: true
      }
    case SET_DOMAINS:
      return {
        ...state,
        domains: action.payload,
        domainsLoaded: true
      }
    case SET_DURATIONS:
      return {
        ...state,
        durations: action.payload,
        durationsLoaded: true
      }
    case SET_RESELLERS:
      return {
        ...state,
        resellers: action.payload,
        resellersLoaded: true
      }
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
        countriesLoaded: true
      }
    case SET_REGIONS:
      return {
        ...state,
        regions: action.payload,
        regionsLoaded: true
      }
    case SET_RESORT_PARTNERS:
      return {
        ...state,
        resortPartners: action.payload,
        resortPartnersLoaded: true
      }
    case SET_GUARANTEES:
      return {
        ...state,
        guarantees: action.payload,
        guaranteesLoaded: true
      }
    case SET_EXCLUSIONS:
      return {
        ...state,
        exclusions: action.payload,
        exclusionsLoaded: true
      }
    case SET_VOUCHERS:
      return {
        ...state,
        vouchers: action.payload,
        vouchersLoaded: true
      }
    default:
      return state;
  }
}

export default articlesReducer
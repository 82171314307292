import {useContext, useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import ToolbarCustom from '../../../_metronic/layout/components/toolbar/toolbars/ToolbarCustom'

import { ProductsContext } from '../../context'
import { itemWithId } from '../../utils'
import GenericProductDetailModal from './GenericProductDetailModal'
import SpecificProductDetailModal from './SpecificProductDetailModal'
import LocalProductDetailModal from './LocalProductDetailModal'
import { GridActionsCellItem } from '@mui/x-data-grid'
import ImportCollectionModal from '../../components/ImportCollectionModal'
import { GENERIC_PRODUCTS, LOCAL_PRODUCTS, SPECIFIC_PRODUCTS } from '../../constants/collections'
import config from '../../../config.json'
import DataGridComponent from '../../components/DataGrid'

const ProductsPage = ({ type }) => {
  
  const {
    genericProducts,
    genericProductsLoaded,
    specificProducts,
    specificProductsLoaded,
    localProducts,
    localProductsLoaded,
    fetchProducts,
    createProduct,
    updateProduct,
    deleteProduct,
    resorts,
    resortsLoaded,
    fetchResorts,
    domains,
    domainsLoaded,
    fetchDomains,
    durations,
    durationsLoaded,
    fetchDurations,
    resellers,
    resellersLoaded,
    fetchResellers,
    guarantees,
    guaranteesLoaded,
    fetchGuarantees,
    exclusions,
    exclusionsLoaded,
    fetchExclusions,
  } = useContext(ProductsContext)

  const [currentProduct, setCurrentProduct] = useState(null)
  const [filter, setFilter] = useState({
    season: 'all'
  })
  const [filteredProducts, setFilteredProducts] = useState([])

  const [importCollectionModalOpen, setImportCollectionModalOpen] = useState(false)

  const PRODUCTS = {
    generic: genericProducts,
    specific: specificProducts,
    local: localProducts
  }

  const PRODUCT_TYPE_LOADED = {
    generic: genericProductsLoaded,
    specific: specificProductsLoaded,
    local: localProductsLoaded
  }

  const TITLE = {
    generic: 'Generic Products',
    specific: 'Specific Products',
    local: 'Local Products'
  }

  const COLLECTION_NAME = {
    generic: GENERIC_PRODUCTS,
    specific: SPECIFIC_PRODUCTS,
    local: LOCAL_PRODUCTS
  }  

  useEffect(() => {
    if(!genericProductsLoaded) {
      fetchProducts('generic')
    }
  }, [genericProductsLoaded, fetchProducts, type])

  useEffect(() => {
    if(!specificProductsLoaded) {
      fetchProducts('specific')
    }
  }, [specificProductsLoaded, fetchProducts, type])

  useEffect(() => {
    if(!localProductsLoaded) {
      fetchProducts('local')
    }
  }, [localProductsLoaded, fetchProducts, type])

  useEffect(() => {
    if(!guaranteesLoaded) {
      fetchGuarantees()
    }
  }, [guaranteesLoaded, fetchGuarantees])

  useEffect(() => {
    if(!exclusionsLoaded) {
      fetchExclusions()
    }
  }, [exclusionsLoaded, fetchExclusions])

  useEffect(() => {
    if(!resortsLoaded) {
      fetchResorts()
    }
  }, [resortsLoaded, fetchResorts])

  useEffect(() => {
    if(!domainsLoaded) {
      fetchDomains()
    }
  }, [domainsLoaded, fetchDomains])

  useEffect(() => {
    if(!durationsLoaded) {
      fetchDurations()
    }
  }, [durationsLoaded, fetchDurations])

  useEffect(() => {
    if(!resellersLoaded) {
      fetchResellers()
    }
  }, [resellersLoaded, fetchResellers])

  const convertToTableItem = (product) => {

    const productLabel = (product) => {
      if(!product) return "Missing product"
      if(type === "local") {
        const resort = itemWithId(resorts, product.resort)
        const specificProduct = itemWithId(specificProducts, product.specific_product)
        return `${specificProduct?.name?.fr || '[Missing specific product]'} - ${resort?.name?.fr || '[Missing resort]'}`
      }
      return product.name?.fr || "Missing product name"
    }

    return {
      id: product.id,
      product_label: productLabel(product),
      domain_label: itemWithId(domains, product.domain)?.title?.fr || 'N/A',
      duration_label: itemWithId(durations, product.duration)?.title?.fr || 'N/A',
      created_label: product.meta?.created ? new Date(product.meta.created).toLocaleDateString() : 'N/A',
      updated_label: product.meta?.updated ? new Date(product.meta.updated).toLocaleDateString() : 'N/A',
      active_label: product.active ? 'Active' : 'Disabled',
      resort_label: itemWithId(resorts, product.resort)?.name?.fr || '',
      all_resorts: product.all_resorts,
      generic_product_label: itemWithId(genericProducts, product.generic_product)?.name?.fr || 'N/A',
      specific_product_label: itemWithId(specificProducts, product.specific_product)?.name?.fr || 'N/A',
      price_label: product.price || '',
      sale_start_date_label: product.sale_start_date || '',
      sale_end_date_label: product.sale_end_date || '',
      reseller_label: itemWithId(resellers, product.reseller)?.company_name || '',
      internal_id_label: product.internal_id || '',
    }
  }

  useEffect(() => {
    if(type !== 'local') {
      setFilteredProducts([...PRODUCTS[type]])
    } else {
      setFilteredProducts([...PRODUCTS[type]].toSorted((a, b) => {
        const aSpecific = itemWithId(specificProducts, a.specific_product)
        const aResort = itemWithId(resorts, a.resort)
        const aLabel = `${aSpecific?.name?.fr || ''} - ${aResort?.name?.fr || ''}`.toLowerCase()
        const bSpecific = itemWithId(specificProducts, b.specific_product)
        const bResort = itemWithId(resorts, b.resort)
        const bLabel = `${bSpecific?.name?.fr || ''} - ${bResort?.name?.fr || ''}`.toLowerCase()
        return aLabel.localeCompare(bLabel)
      }))
    }
  }, [PRODUCTS[type], specificProductsLoaded, resortsLoaded, domains, domainsLoaded, durations, durationsLoaded, resorts, resortsLoaded, genericProducts, genericProductsLoaded, resellers, resellersLoaded])

  // GENERIC PRODUCT FIELDS
  // name: string (localized)
  // slug: string (localized)
  // intro: string (localized)
  // domain: reference (domains)
  // duration: reference (durations)
  // ipid: url
  // cg: url
  // brochure: url
  // image: url
  // cover_image: url
  // guranatees: reference (guranatees) [array]
  // exclusions: reference (exclusions)

  // SPECIFIC PRODUCT FIELDS
  // generic_product: reference (generic_products)
  // internal_id: string
  // ipid: url
  // cg: url
  // share_insurer: number
  // share_broker: number
  // share_co_broker: number

  // LOCAL PRODUCT FIELDS
  // specific_product: reference (specific_products)
  // resort: reference (resorts)
  // price: number
  // insurance_tax: number
  // share_operator: number
  // share_club: number
  // share_other: number
  // share_webapp: number
  // sale_start_date: date (DD/MM)
  // sale_end_date: date (DD/MM)

  const onCreateClick = () => {
    let newProduct
    if(type === 'generic') {
      newProduct = {
        id: '',
        name: {},
        intro: {},
        domain: '',
        duration: '',
        ipid: '',
        cg: '',
        brochure: '',
        image: '',
        cover_image: '',
        guarantees: [],
        exclusions: ''
      }
    } else if(type === 'specific') {
      newProduct = {
        id: '',
        name: {},
        generic_product: '',
        internal_id: '',
        ipid: '',
        cg: '',
        share_insurer: 0,
        share_broker: 0,
        share_co_broker: 0
      }
    } else if(type === 'local') {
      newProduct = {
        id: '',
        specific_product: '',
        resort: '',
        price: 0,
        insurance_tax: 0,
        share_operator: 0,
        share_club: 0,
        share_other: 0,
        share_webapp: 0,
        sale_start_date: '',
        sale_end_date: ''
      }
    }
    setCurrentProduct(newProduct)
  }

  const handleEdit = (productId) => {
    const product = itemWithId(type === 'generic' ? genericProducts : type === 'specific' ? specificProducts : localProducts, productId)
    setCurrentProduct(product)
  }

  const onDetailSubmit = async (data) => {
    // const data = {...currentProduct}
    if(!data.id) {
      await createProduct(type, data)
    } else {
      await updateProduct(type, data.id, data)
    }
    setCurrentProduct(null)
  }

  const onDetailDelete = async () => {
    if(!confirm(`Are you sure you want to delete this ${type} product? (${currentProduct?.name?.fr || currentProduct.id})`)) return
    const deleteResponse = await deleteProduct(type, currentProduct.id)
    if(deleteResponse.error) {
      alert(deleteResponse.error.message || deleteResponse.error || "Error deleting product")
      return
    }
    setCurrentProduct(null)
  }

  const labelForSeason = (season) => {
    switch(season) {
      case 'summer':
        return 'Summer'
      case 'winter':
        return 'Winter'
      case 'universal':
        return 'Universal'
      default:
        return ''
    }
  }

  const handleDuplicate = async (productId) => {
    const product = itemWithId(type === 'generic' ? genericProducts : type === 'specific' ? specificProducts : localProducts, productId)
    if(!confirm(`Are you sure you want to duplicate this ${type} product? (${product.name?.fr || product.id})`)) return
    const name = {}
    for(let lang in product.name) {
      name[lang] = `Copy of ${product.name[lang]}`
    }
    await createProduct(type, {
      ...product,
      name,
      active: false
    })
  }

  const tableFieldsForType = (type) => {
    let fields = []
    if(type === 'generic') {
      fields = [{
        field: 'product_label',
        headerName: 'Name',
        minWidth: 250,
        flex: 1,
      }, {
        field: 'domain_label',
        headerName: 'Domain',
        width: 200
      }, {
        field: 'duration_label',
        headerName: 'Duration',
        width: 200
      }, {
        field: 'created_label',
        headerName: 'Created',
      }, {
        field: 'updated_label',
        headerName: 'Updated',
      }, {
        field: 'active_label',
        headerName: 'Active',
        renderCell: ({ row }) => {
          return <span className={`text-center badge badge-${row.active_label === 'Active' ? 'success' : 'secondary'} fs-8`}>{row.active_label}</span>
        },
      }, {
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<i className="bi bi-copy text-primary"></i>}
              label="Duplicate"
              data-toggle="tooltip"
              data-placement="top"
              title="Duplicate"
              onClick={() => handleDuplicate(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<i className="bi bi-pencil-square text-primary"></i>}
              label="Edit"
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
              onClick={() => handleEdit(id)}
              color="inherit"
            />,
          ];
        }
      }]
    } if(type === 'specific') {
      fields = [{
        field: 'product_label',
        headerName: 'Name',
        minWidth: 250,
        flex: 1
      }, {
        field: 'generic_product_label',
        headerName: 'Generic Product',
        width: 250
      }, {
        field: 'internal_id_label',
        headerName: 'Internal ID',
      }, {
        field: 'created_label',
        headerName: 'Created',
      }, {
        field: 'updated_label',
        headerName: 'Updated',
      }, {
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<i className="bi bi-copy text-primary"></i>}
              label="Duplicate"
              className="textPrimary"
              onClick={() => handleDuplicate(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<i className="bi bi-pencil-square text-primary"></i>}
              label="Edit"
              className="textPrimary"
              onClick={() => handleEdit(id)}
              color="inherit"
            />,
          ];
        }
      }]
    } if(type === 'local') {
      fields = [{
        field: 'specific_product_label',
        headerName: 'Specific Product',
        minWidth: 250,
        flex: 1
      }, {
        field: 'resort_label',
        headerName: 'Resort',
        width: 250,
        renderCell: ({ row }) => {
          if(row.all_resorts) return <span className='badge badge-success fs-8'>All resorts</span>
          if(!row.resort_label) return  <span className='badge badge-danger fs-8'>No resort</span>
          return row.resort_label
        }
      }, {
        field: 'price_label',
        headerName: 'Price',
      }, {
        field: 'sale_start_date_label',
        headerName: 'Sale Start',
      }, {
        field: 'sale_end_date_label',
        headerName: 'Sale End',
      }, {
      //   field: 'reseller_label',
      //   headerName: 'Network',
      // }, {
        field: 'created_label',
        headerName: 'Created',
      }, {
        field: 'updated_label',
        headerName: 'Updated',
      }, {
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<i className="bi bi-pencil-square text-primary"></i>}
              label="Edit"
              className="textPrimary"
              onClick={() => handleEdit(id)}
              color="inherit"
            />,
          ];
        }
      }]
    }
    return fields
  }

  const promptImportCollection = () => {
    setImportCollectionModalOpen(true)
  }

  return (
    <>
      <ToolbarCustom
        pageTitle={TITLE[type]}
        primaryButton={PRODUCT_TYPE_LOADED[type] && { onClick: onCreateClick, title: 'Add' }}
        filterElements={[(
          config.environment !== 'staging' && <button className='btn btn-sm fw-bold btn-primary' onClick={promptImportCollection}>
            <KTSVG path='/media/icons/duotune/arrows/arr037.svg' className='svg-icon-3' />
            Import from staging
          </button>
        )]}
        loading={!PRODUCT_TYPE_LOADED[type]}
        />
      {/* begin::Row */}
      <div className='mt-3'>
        { PRODUCT_TYPE_LOADED[type] && 
          <DataGridComponent
            id={`products-${type}`}
            rows={filteredProducts.map(convertToTableItem)}
            columns={tableFieldsForType(type)}
            pageSizeOptions={[20, 50, 100]}
          />
        }
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gx-5 gx-xl-10'>
        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
      <GenericProductDetailModal
        show={type === 'generic' && Boolean(currentProduct)}
        handleClose={() => setCurrentProduct(null)}
        product={type === 'generic' ? currentProduct : null}
        setProduct={setCurrentProduct}
        onSubmit={onDetailSubmit}
        onDelete={onDetailDelete} />
      <SpecificProductDetailModal
        show={type === 'specific' && Boolean(currentProduct)}
        handleClose={() => setCurrentProduct(null)}
        product={type === 'specific' ? currentProduct : null}
        setProduct={setCurrentProduct}
        onSubmit={onDetailSubmit}
        onDelete={onDetailDelete} />
      <LocalProductDetailModal
        show={type === 'local' && Boolean(currentProduct)}
        handleClose={() => setCurrentProduct(null)}
        product={type === 'local' ? currentProduct : null}
        setProduct={setCurrentProduct}
        onSubmit={onDetailSubmit}
        onDelete={onDetailDelete} />
      <ImportCollectionModal
        isOpen={importCollectionModalOpen}
        onClose={() => setImportCollectionModalOpen(false)}
        collectionName={COLLECTION_NAME[type]}
        sourceEnvironment={"staging"}
        onImportComplete={async () => {
          // reload collection
          await fetchProducts(type)
        }} />
    </>
  )
}

const ProductsWrapper = ({ type }) => {
  return (
    <>
      <ProductsPage type={type} />
    </>
  )
}

export default ProductsWrapper

const CONTENT_PAGE_ARTICLE_IDS = [
  'contact',
  'commercial-contact',
  'articles-hub',
  'products-hub',
]

const itemWithId = (array, id) => {
  if(!array) return null
  return array.find(i => i.id === id)
}

export {
  CONTENT_PAGE_ARTICLE_IDS,
  itemWithId,
}

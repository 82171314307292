import React, { useEffect, useState } from "react"
import { DataGrid } from "@mui/x-data-grid"
import config from "../../config.json"
import PropTypes from 'prop-types'

const DataGridComponent = ({ rows, columns, id, pageSizeOptions = [10, 20, 50] }) => {

  const defaultFilterModel = {items: [], logicOperator: "and", quickFilterLogicOperator: "and", quickFilterValues: []}
  const defaultSortModel = []
  const [filterModel, setFilterModel] = useState(defaultFilterModel)
  const [sortModel, setSortModel] = useState(defaultSortModel)

  const setCookie = (name, value, attributes = {}) => {

    attributes = {
      path: '/',
      // add other defaults here if necessary
      ...attributes
    };
  
    if (attributes.expires instanceof Date) {
      attributes.expires = attributes.expires.toUTCString();
    }
  
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
  
    for (let attributeKey in attributes) {
      updatedCookie += "; " + attributeKey;
      let attributeValue = attributes[attributeKey];
      if (attributeValue !== true) {
        updatedCookie += "=" + attributeValue;
      }
    }
  
    document.cookie = updatedCookie;
  }

  useEffect(() => {
    const getCookie = (name) => {
      let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([.$?*|{}()[]\\\/\+^])/g, '\\$1') + "=([^;]*)"
      ));
      return matches ? decodeURIComponent(matches[1]) : undefined;
    }
    const filterModelFromCookie = getCookie(`assuranceski_${config.environment}_${id}_filterModel`)
    if(filterModelFromCookie) {
      setFilterModel(JSON.parse(filterModelFromCookie))
    }
    const sortModelFromCookie = getCookie(`assuranceski_${config.environment}_${id}_sortModel`)
    if(sortModelFromCookie) {
      setSortModel(JSON.parse(sortModelFromCookie))
    }
  }, [document.cookie])

  const handleChangeFilterModel = (model) => {
    setFilterModel(model)
    setCookie(`assuranceski_${config.environment}_${id}_filterModel`, JSON.stringify(model))
  }

  const handleChangeSortModel = (model) => {
    setSortModel(model)
    setCookie(`assuranceski_${config.environment}_${id}_sortModel`, JSON.stringify(model))
  }

  return (
    <DataGrid
      rowSelection={false}
      rows={rows}
      sortModel={sortModel}
      filterModel={filterModel}
      onSortModelChange={handleChangeSortModel}
      onFilterModelChange={handleChangeFilterModel}
      columns={columns.map((column) => ({
        ...column,
        headerClassName: 'table--header-cell',
        cellClassName: 'table--body-cell',
      }))}
      pageSizeOptions={pageSizeOptions}
    />
  )
}
DataGridComponent.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  pageSizeOptions: PropTypes.array
}

export default DataGridComponent

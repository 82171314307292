import React, {useContext, useEffect, useState} from 'react'
import moment from 'moment'
import ToolbarCustom from '../../../_metronic/layout/components/toolbar/toolbars/ToolbarCustom'

import { CustomersContext, ProductsContext } from '../../context'
import PurchaseOrderDetailModal from './PurchaseOrderDetailModal'
import DataGridComponent from '../../components/DataGrid'
import { GridActionsCellItem } from '@mui/x-data-grid'
import { KTSVG } from '../../../_metronic/helpers'

const PurchaseOrdersPage = () => {
  
  const {
    customersLoaded,
    fetchCustomers,
    purchaseOrders,
    purchaseOrdersLoaded,
    fetchPurchaseOrders,
  } = useContext(CustomersContext)

  const {
    resortsLoaded,
    fetchResorts,
    domainsLoaded,
    fetchDomains,
    durationsLoaded,
    fetchDurations,
    durations
  } = useContext(ProductsContext)

  const [currentOrder, setCurrentOrder] = useState(null)
  const [filteredOrders, setFilteredOrders] = useState([])

  const [search, setSearch] = useState('')

  useEffect(() => {
    if(!customersLoaded) {
      fetchCustomers()
    }
  }, [customersLoaded, fetchCustomers])

  useEffect(() => {
    if(!purchaseOrdersLoaded) {
      fetchPurchaseOrders()
    }
  }, [purchaseOrdersLoaded, fetchPurchaseOrders])

  useEffect(() => {
    if(!resortsLoaded) {
      fetchResorts()
    }
  }, [resortsLoaded, fetchResorts])

  useEffect(() => {
    if(!domainsLoaded) {
      fetchDomains()
    }
  }, [domainsLoaded, fetchDomains])

  useEffect(() => {
    if(!durationsLoaded) {
      fetchDurations()
    }
  }, [durationsLoaded, fetchDurations])

  const onViewClick = (purchase) => {
    setCurrentOrder(purchase)
  }

  useEffect(() => {

    let fp = [...purchaseOrders]
    // default sort by date, rest is handled by the grid component
    fp.sort((a, b) => {
      const aDate = a.meta?.created || 0
      const bDate = b.meta?.created || 0
      if(aDate < bDate) -1
      if(aDate > bDate) 1
      return 0
    })

    if(search) {
      const searchTokens = search.split(' ').filter(t => t.length > 0)
      fp = fp.filter(purchase => {
        if(!purchase) {
          return false
        }
        const fields = [purchase.billing.first_name, purchase.billing.last_name, purchase.billing.email, purchase.id]
        // check if all tokens are found in at least one field
        for(let token of searchTokens) {
          if(!fields.find(f => f.toLowerCase().includes(token.toLowerCase()))) {
            return false
          }
        }
        return true
      })
    }
    setFilteredOrders(fp)
  }, [purchaseOrders, search])

  const itemWithId = (array, id) => {
    return array.find(i => i.id === id)
  }

  const formattedPrice = (price) => {
    return `€ ${parseFloat((price / 100)).toFixed(2)}`.replace('.', ',')
  }

  const convertToTableItem = (purchaseOrder) => {
    const duration = itemWithId(durations, purchaseOrder?.duration)
    const earliestDate = purchaseOrder?.dates?.sort((a, b) => a - b)[0]
    const earliesDateFormatted = earliestDate ? moment(earliestDate).format('DD/MM/YYYY') : duration?.title?.fr
    const daysRemaining = earliestDate ? moment(earliestDate).diff(moment(), 'days') : 'N/A'
    return {
      ...purchaseOrder,
      customer_name: `${purchaseOrder.billing.first_name} ${purchaseOrder.billing.last_name}`,
      customer_email: purchaseOrder.billing.email,
      date: purchaseOrder.meta?.created,
      closest_insured_date: earliesDateFormatted,
      days_remaining: daysRemaining,      
    }
  }

  return (
    <>
      <ToolbarCustom
        pageTitle={'Purchase orders'}
        filterElements={[
          <div
            key={`filter-element-search`}
            data-kt-search-element='form'
            className='w-300px position-relative'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
            />
            <input
              type='text'
              className='form-control form-control-flush ps-9 pe-9'
              name='search'
              value={search}
              placeholder='Customer name, email, reference ID'
              data-kt-search-element='input'
              onChange={(e) => setSearch(e.target.value)}
            />
            {/* clear search field */}
            {search && (
              <div
                className='btn btn-flush btn-active-color-primary position-absolute top-50 ms-0 translate-middle-y end-0'
                onClick={() => setSearch('')}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500'
                />
              </div>
            )}
          </div>]}
        loading={!purchaseOrdersLoaded}
        />
      {/* begin::Row */}
      <div className='mt-5'>
        <DataGridComponent
          id="purchase-orders"
          rows={filteredOrders.map(convertToTableItem)}
          columns={[{
            field: 'billing',
            headerName: 'Customer',
            flex: 2,
            valueFormatter: billing => `${billing?.first_name || ''}${billing?.first_name ? ' ' : ''}${billing?.last_name || ''} (${billing?.email || ''})`,
            sortComparator: (v1, v2) => {
              const v1Name = `${v1?.first_name || ''}${v1?.first_name ? ' ' : ''}${v1?.last_name || ''}`.toLowerCase()
              const v2Name = `${v2?.first_name || ''}${v2?.first_name ? ' ' : ''}${v2?.last_name || ''}`.toLowerCase()
              if(v1Name > v2Name) return 1
              if(v1Name < v2Name) return -1
              return 0
            }
          }, {
            field: 'amount',
            headerName: 'Amount',
            valueFormatter: (value) => formattedPrice(value),
          }, {
            field: 'id',
            headerName: 'Reference',
            flex: 1,
          }, {
            field: 'date',
            headerName: 'Date created',
            flex: 1,
            valueFormatter: (value) => value ? moment(value).format('DD/MM/YYYY HH:mm') : 'N/A',
          }, {
            field: 'closest_insured_date',
            headerName: 'Earliest insured date',
            flex: 1,            
          }, {
            field: 'days_remaining',
            headerName: 'Days remaining',
            flex: 1,
            renderCell: ({ row }) => {
              if(row.status !== 'pending') {
                return null
              }
              return <span className={`text-center badge badge-${row.days_remaining === "N/A" ? 'secondary' : row.days_remaining < 1 ? 'danger' : row.days_remaining < 4 ? 'warning' : 'success'} fs-8`}>{row.days_remaining}</span>
            }
          }, {
            field: 'status',
            headerName: 'Status',
            renderCell: ({ row }) => {
              return <span className={`text-center badge badge-${row.status === 'paid' ? 'success' : row.status === 'cancelled' ? 'secondary' : 'warning'} fs-8`}>{row.status}</span>
            },
          }, {
            field: 'actions',
            type: 'actions',
            headerName: '',
            cellClassName: 'actions',
            getActions: ({ id }) => {
              return [
                <GridActionsCellItem
                  key={`view-${id}`}
                  icon={<i className="bi bi-eye text-primary fs-4"></i>}
                  label="View"
                  className="textPrimary"
                  onClick={() => onViewClick(itemWithId(filteredOrders, id))}
                  color="inherit"
                />,
              ];
            },
            width: 60
          }]}
          pageSizeOptions={[20, 50, 100]}
        />
      </div>
      {/* end::Row */}
      <PurchaseOrderDetailModal
        show={Boolean(currentOrder)}
        handleClose={() => setCurrentOrder(null)}
        purchase={currentOrder} />
    </>
  )
}

const PurchaseOrdersWrapper = () => {
  return (
    <>
      <PurchaseOrdersPage />
    </>
  )
}

export default PurchaseOrdersWrapper
